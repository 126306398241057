import { Comparators } from '../../node_modules/react-ui-basics/Tools.js';

IDBRequest.prototype['asPromise'] = function () {
    return new Promise((resolve, reject) => {
        this.onsuccess = e => resolve(this.result);
        this.onerror = reject;
    });
};
IDBRequest.prototype['then'] = function (resolve, reject) {
    this.onsuccess = e => resolve(this.result);
    this.onerror = reject;
};
IDBTransaction.prototype['asPromise'] = function () {
    return new Promise((resolve, reject) => {
        this.onsuccess = e => resolve(this);
        this.onerror = reject;
    });
};
IDBTransaction.prototype['then'] = function (resolve, reject) {
    this.onsuccess = e => resolve(this);
    this.onerror = reject;
};
class DB {
    db;
    name;
    migrations;
    constructor(name, migrations) {
        this.name = name;
        this.migrations = migrations;
    }
    open() {
        return new Promise((resolve, reject) => {
            const migrations = this.migrations;
            migrations.sort(Comparators.of((it => it.version()), Comparators.SORT_ASC, migrations));
            const version = migrations[migrations.length - 1]?.version() || 0;
            const openDBRequest = window.indexedDB.open(this.name, version);
            openDBRequest.onerror = event => {
                console.error(event);
                reject(event);
            };
            openDBRequest.onsuccess = event => {
                console.log('onsuccess', event);
                this.db = event.target['result'];
                resolve(this.db);
            };
            openDBRequest.onupgradeneeded = async (event) => {
                console.log('onupgradeneeded', event);
                const db = event.target['result'];
                this.db = db;
                const filtered = migrations.filter(it => it.version() > event.oldVersion);
                const tr = event.target['transaction'];
                for (let i = 0; i < filtered.length; i++) {
                    const it = filtered[i];
                    console.log('executing migration', it.version(), it.name());
                    await it.execute(tr, db, this);
                }
            };
        });
    }
    trRO(...stores) {
        return this.tr('readonly', ...stores);
    }
    trRW(...stores) {
        return this.tr('readwrite', ...stores);
    }
    tr(mode, ...stores) {
        // debugger
        let tr = this.db.transaction(stores, mode);
        tr.oncomplete = event => {
            console.log("transaction.oncomplete");
        };
        tr.onerror = event => {
            console.error(event);
        };
        return tr;
    }
}

export { DB as default };
