import { Store } from '../../node_modules/react-ui-basics/store/Store.js';

const store = new Store({
    queue: []
});
const remove = (task) => {
    store.set(state => {
        const i = state.queue.findIndex(it => it.url === task.url);
        if (i >= 0)
            state.queue.splice(i, 1);
    });
};
const downloadAll = (tasks) => {
    store.set(state => {
        state.queue.push(...tasks);
    });
};
const download = (url, artist, album, song, format, bitrate, onData) => {
    store.set(state => {
        if (!state.queue.some(it => it.url === url))
            state.queue.push({ url, song, artist, album, format, bitrate, onData });
    });
};

export { store as default, download, downloadAll, remove, store };
