import { Store } from '../../node_modules/react-ui-basics/store/Store.js';

const store = new Store({});
const add = (url, blobUrl) => {
    store.set(state => {
        state[url] = blobUrl;
        return state;
    });
};

export { add, store as default, store };
