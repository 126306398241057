import { jsx as jsxRuntime_1, jsxs as jsxRuntime_2 } from 'preact/jsx-runtime';
import { useState as react_8, useEffect as react_7 } from 'preact/compat';
import { hide, store } from '../stores/DialogStore.js';
import Modal from '../../node_modules/react-ui-basics/Modal.js';
import { classNames } from '../../node_modules/react-ui-basics/Tools.js';
import Button from '../../node_modules/react-ui-basics/Button.js';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';
import MaterialIcon from '../../node_modules/react-ui-basics/MaterialIcon.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';

var Dialog = () => {
    const { show, accept, cancel, onAccept, onCancel, title, description, buttons } = useStore(store);
    const [open, setOpen] = react_8();
    const [close, setClose] = react_8();
    react_7(() => {
        if (show) {
            // @ts-ignore
            open && open();
        }
        else {
            // @ts-ignore
            close && close();
        }
    }, [show]);
    // console.log('Dialog.render', show)
    return jsxRuntime_1(Modal, { className: classNames('DialogModal', u `> .overlay{transition:background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);background-color:rgb(0 0 0 / 0%);&.show{background-color:rgb(0 0 0 / 67%);}}`), open: open => setOpen(() => open), close: close => setClose(() => close), onClose: () => {
            hide();
            onCancel?.();
        }, container: document.getElementById('root'), closeIcon: jsxRuntime_1(MaterialIcon, { icon: 'close' }), children: jsxRuntime_2("div", { className: "Dialog", children: [title && jsxRuntime_1("div", { className: "title", children: title }), description && jsxRuntime_1("div", { className: "description", children: description }), jsxRuntime_2("div", { className: "row end", children: [cancel && jsxRuntime_1(Button, { flat: true, onClick: onCancel, children: cancel }), accept && jsxRuntime_1(Button, { flat: true, onClick: onAccept, children: accept }), buttons] })] }) });
};

export { Dialog as default };
