import { jsxs as jsxRuntime_2, jsx as jsxRuntime_1, Fragment as jsxRuntime_3 } from 'preact/jsx-runtime';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';
import { store, setAll, set } from '../stores/ArtistsStore.js';
import { styled as j, css as u } from '../../node_modules/goober/dist/goober.esm.js';
import NetworkService from '../services/NetworkService.js';
import MaterialIcon from '../../node_modules/react-ui-basics/MaterialIcon.js';
import { useEffect as react_7, useRef as react_12, useState as react_8 } from 'preact/compat';
import { formatDuration, getAlbumDuration } from '../utils/Helpers.js';
import { pushLocation } from '../../node_modules/react-ui-basics/router/HistoryTools.js';
import Route$1 from '../../node_modules/react-ui-basics/router/Route.js';
import { FlexRow, FlexColumn } from './SharedComponents.js';
import { addEventListener, WINDOW, removeEventListener, Comparators, classNames } from '../../node_modules/react-ui-basics/Tools.js';
import { SORT_ASC } from '../../node_modules/react-ui-basics/Table.js';
import { store as store$1, play } from '../stores/PlayerStore.js';
import Button from '../../node_modules/react-ui-basics/Button.js';
import Scrollable from '../../node_modules/react-ui-basics/Scrollable.js';
import { useLocalCache } from '../services/LocalCacheService.js';
import { store as store$2 } from '../stores/SettingsStore.js';
import { store as store$3, downloadAll, download } from '../stores/DownloadQueueStore.js';
import { useWindowSize } from '../utils/Hooks.js';
import NavLink$1 from '../../node_modules/react-ui-basics/router/NavLink.js';
import AlbumCover from './AlbumCover.js';

const Album = j("div") `display:inline-flex;flex-flow:column nowrap;padding:5px;align-items:center;width:200px;box-sizing:border-box;&:hover{cursor:pointer;}`;
const AlbumTitle = j("span") `margin:5px;font-size:16px;text-align:center;`;
const AlbumDuration = j("span") `font-size:12px;`;
const AlbumArtist = j("span") `font-size:12px;text-align:center;&:nth-child(2){margin-top:5px;}`;
const LinkStyles = u `margin-right:20px;font-weight:bold;text-transform:uppercase;text-decoration:none;color:gray;`;
const LinkActiveStyles = u `color:#038acc;`;
const Library = ({ artistId, album }) => {
    const artistsStore = useStore(store);
    const { queue } = useStore(store$1);
    artistsStore.ids.map(id => artistsStore.map[id].albums.map(it => ({ ...it, artistId: id }))).flat();
    react_7(() => {
        NetworkService.getArtists().then(setAll);
    }, []);
    react_7(() => {
        artistId && NetworkService.getArtist({ id: artistId }).then(set);
    }, [artistId]);
    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 800;
    let albumCardWidth = '200px';
    if (isMobile) {
        albumCardWidth = `${(windowSize.width) / 2 - 10}px`;
    }
    const scrollRef = react_12();
    react_7(() => {
        const listener = (event) => {
            scrollRef.current?.setScroll(event.state?.scroll || 0);
        };
        addEventListener(WINDOW, "popstate", listener);
        return () => {
            removeEventListener(WINDOW, "popstate", listener);
        };
    }, []);
    return jsxRuntime_2(Scrollable, { ref: scrollRef, className: u `max-width:100%;padding-right:0;max-height:${queue.length ? windowSize.height - 151 :windowSize.height}px !important;> .viewport{text-align:center;padding-top:19px;scroll-behavior:auto;}`, children: [jsxRuntime_2(FlexRow, { className: u `margin-left:50px;margin-bottom:20px;`, children: [jsxRuntime_1(NavLink$1, { className: LinkStyles, activeClassName: LinkActiveStyles, href: '/', highlightPath: '/', children: "Artists" }), jsxRuntime_1(NavLink$1, { className: LinkStyles, activeClassName: LinkActiveStyles, href: '/albums/', highlightPath: '/albums/*', children: "Albums" })] }), jsxRuntime_1(Route$1, { path: "/", children: jsxRuntime_1(ListArtists, { cardWidth: albumCardWidth, pushLocation: path => {
                        WINDOW.history.replaceState({ scroll: scrollRef.current.getScroll() }, null, null);
                        pushLocation(path);
                    } }) }), jsxRuntime_1(Route$1, { path: "/albums", children: jsxRuntime_1(ListAlbums, { cardWidth: albumCardWidth }) }), jsxRuntime_1(Route$1, { path: "/:artistId(^[0-9]+$)?", children: jsxRuntime_1(ListArtistAlbums, { cardWidth: albumCardWidth, artistId: null }) }), jsxRuntime_1(Route$1, { path: "/:artistId/:albumName", children: jsxRuntime_1(ListSongs, { albumName: null, artistId: null }) })] });
};
const ListAlbums = ({ cardWidth }) => {
    const artistsStore = useStore(store);
    const albums = artistsStore.ids.map(id => artistsStore.map[id].albums.map(it => ({ ...it, artistId: id }))).flat();
    albums.sort(Comparators.of(it => it.name, Comparators.SORT_ASC, albums));
    return jsxRuntime_1(jsxRuntime_3, { children: albums.map(it => jsxRuntime_2(Album, { className: u `width:${cardWidth};`, onClick: e => {
                pushLocation(`/${it.artistId}/${it.name}`);
            }, children: [jsxRuntime_1(AlbumCover, { artistId: it.artistId, album: it }), jsxRuntime_1(AlbumTitle, { children: it.name }), jsxRuntime_1(AlbumArtist, { children: artistsStore.map[it.artistId].name }), jsxRuntime_1(AlbumDuration, { children: formatDuration(getAlbumDuration(it)) })] })) });
};
const Artist = j("div") `display:inline-flex;flex-flow:column nowrap;padding:5px;align-items:center;width:200px;box-sizing:border-box;&:hover{cursor:pointer;}`;
const ListArtists = ({ cardWidth, pushLocation }) => {
    const artistsStore = useStore(store);
    let ids = artistsStore.ids.filter(id => artistsStore.map[id].albums.length > 0);
    ids.sort(Comparators.of(id => artistsStore.map[id].name, Comparators.SORT_ASC, ids));
    return jsxRuntime_1(FlexRow, { className: u `flex-flow:row wrap;justify-content:center;align-items:start;`, children: ids.map(id => {
            const artist = artistsStore.map[id];
            let albumsWithCovers = artist.albums.filter(it => !!it.coverPath);
            if (albumsWithCovers.length > 4) {
                albumsWithCovers.sort(Comparators.of(getAlbumDuration, Comparators.SORT_DESC, albumsWithCovers));
            }
            return jsxRuntime_2(Artist, { className: u `width:${cardWidth};`, onClick: e => {
                    if (artist.albums.length > 1) {
                        pushLocation(`/${id}/`);
                    }
                    else
                        pushLocation(`/${id}/${artist.albums[0].name}`);
                }, children: [albumsWithCovers.length === 1 &&
                        jsxRuntime_1(AlbumCover, { artistId: id, album: albumsWithCovers[0] }), (albumsWithCovers.length === 2 || albumsWithCovers.length === 3) &&
                        jsxRuntime_2(FlexColumn, { className: u `border-radius:4px;overflow:hidden;`, children: [jsxRuntime_2(FlexRow, { children: [jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[0] }), jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[1] })] }), jsxRuntime_2(FlexRow, { children: [jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[1] }), jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[0] })] })] }), albumsWithCovers.length >= 4 &&
                        jsxRuntime_2(FlexColumn, { className: u `border-radius:4px;overflow:hidden;`, children: [jsxRuntime_2(FlexRow, { children: [jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[0] }), jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[1] })] }), jsxRuntime_2(FlexRow, { children: [jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[2] }), jsxRuntime_1(AlbumCover, { className: 'small', artistId: id, album: albumsWithCovers[3] })] })] }), albumsWithCovers.length === 0 && jsxRuntime_1(AlbumCover, { artistId: id }), jsxRuntime_1(AlbumArtist, { children: artist.name }), jsxRuntime_2(AlbumDuration, { children: [artist.albums.length, " ", artist.albums.length === 1 ? 'album' : 'albums'] })] });
        }) });
};
const ListArtistAlbums = ({ artistId, cardWidth }) => {
    const artistsStore = useStore(store);
    const artist = artistsStore.map[artistId];
    const albums = [...(artist?.albums || [])];
    react_7(() => {
        artistId && NetworkService.getArtist({ id: artistId }).then(set);
    }, [artistId]);
    albums.sort(Comparators.of(it => it.name, Comparators.SORT_ASC, albums));
    if (!artistId)
        return null;
    return jsxRuntime_2(jsxRuntime_3, { children: [jsxRuntime_2(FlexRow, { className: u `justify-content:space-between;padding:0 40px;`, children: [jsxRuntime_1("span", { className: u `font-size:16px;`, children: artist?.name }), jsxRuntime_1(Button, { className: classNames('red', u `padding:10px !important;height:unset;min-width:50px !important;.MaterialIcon{font-size:30px;color:white;}`), flat: true, round: true, onClick: e => play(albums.flatMap(album => album.songs.map(it => ({
                            artistId: artist.id,
                            albumId: album.id,
                            songId: it.id
                        })))), children: jsxRuntime_1(MaterialIcon, { icon: 'play_arrow' }) })] }), jsxRuntime_1(FlexRow, { className: u `flex-flow:row wrap;justify-content:center;align-items:start;`, children: albums.map(it => jsxRuntime_2(Album, { className: u `width:${cardWidth};`, onClick: e => {
                        pushLocation(`/${artistId}/${it.name}`);
                    }, children: [jsxRuntime_1(AlbumCover, { artistId: artistId, album: it }), jsxRuntime_1(AlbumTitle, { children: it.name }), jsxRuntime_1(AlbumArtist, { children: artist.name }), jsxRuntime_1(AlbumDuration, { children: formatDuration(getAlbumDuration(it)) })] })) })] });
};
const ListSongs = ({ artistId, albumName }) => {
    const [notReadySongs, setNotReadySongs] = react_8([]);
    const { format, bitrate } = useStore(store$2);
    const cache = useLocalCache();
    const downloadQueueState = useStore(store$3);
    const artistsStore = useStore(store);
    const artist = artistsStore.map[artistId];
    albumName = decodeURIComponent(albumName);
    const album = artist?.albums?.find(it => it.name === albumName);
    react_7(() => {
        artistId && NetworkService.getArtist({ id: artistId }).then(set);
    }, [artistId]);
    const refSeparatorSongs = react_12();
    const [downloadClicked, setDownloadClicked] = react_8(false);
    const songs = album ? [...album.songs] : [];
    songs.sort(Comparators.of('track', SORT_ASC, songs));
    react_7(() => {
        if (!cache)
            return;
        if (!songs.length)
            return;
        cache && (async () => {
            let notReady = [];
            for (let i = 0; i < songs.length; i++) {
                const url = NetworkService.baseurl + '/artists/' + artist.id + '/' + album.id + '/' + songs[i].id + '/' + format + '/' + bitrate;
                const cachedSong = await cache.songByUrl(url);
                if (!cachedSong) {
                    notReady.push({
                        url,
                        song: songs[i].title,
                        album: album.name,
                        artist: artist.name,
                        bitrate,
                        format
                    });
                }
            }
            setNotReadySongs(notReady);
        })();
    }, [cache, album?.songs, downloadQueueState.queue]);
    if (!album)
        return jsxRuntime_1(jsxRuntime_3, {});
    let isMobile = window.innerWidth <= 800;
    return jsxRuntime_2(FlexRow, { className: u `margin:${isMobile ? 0 :'20px'};margin-bottom:0;flex-flow:${isMobile ? 'column' :'row'};align-items:${isMobile ? 'center' :'flex-start'};`, children: [jsxRuntime_1(AlbumCover, { artistId: artist.id, album: album, className: classNames(isMobile && 'mobile') }), !isMobile && jsxRuntime_1("span", { className: u `width:25px;` }), jsxRuntime_2(FlexColumn, { className: u `flex-basis:1px;flex-grow:1;padding-left:20px;padding-right:20px;width:100%;box-sizing:border-box;`, children: [jsxRuntime_2(FlexRow, { className: u `justify-content:space-between;`, children: [jsxRuntime_2(FlexColumn, { children: [album && artist && album.name, jsxRuntime_1("span", { className: u `height:5px;` }), jsxRuntime_2("span", { className: u `font-size:14px;`, children: ["by ", artist.name] })] }), jsxRuntime_1(Button, { className: classNames('red', u `padding:10px !important;height:unset;min-width:50px !important;.MaterialIcon{font-size:30px;color:white;}`), flat: true, round: true, onClick: e => play(songs.map(it => ({
                                    artistId: artist.id,
                                    albumId: album.id,
                                    songId: it.id
                                }))), children: jsxRuntime_1(MaterialIcon, { icon: 'play_arrow' }) })] }), jsxRuntime_1("span", { className: u `height:10px;` }), jsxRuntime_2(FlexRow, { children: ["tracks: ", album.songs.length, jsxRuntime_1("span", { className: u `width:25px;` }), "duration: ", formatDuration(getAlbumDuration(album)), jsxRuntime_1("span", { className: u `width:25px;` }), notReadySongs.length > 0 && jsxRuntime_1(Button, { flat: true, round: true, disabled: downloadClicked, onClick: e => {
                                    setDownloadClicked(true);
                                    downloadAll(notReadySongs);
                                }, children: jsxRuntime_1(MaterialIcon, { icon: 'download_for_offline' }) })] }), jsxRuntime_1("span", { className: u `height:25px;`, ref: refSeparatorSongs }), jsxRuntime_1(Scrollable, { className: u `max-width:100%;max-height:${isMobile ? 9999 + 'px' :(refSeparatorSongs.current ? (window.innerHeight - 151 - refSeparatorSongs.current.getBoundingClientRect().bottom) + 'px' :'600px')};`, children: songs.map(it => jsxRuntime_1(Song, { artist: artist, album: album, song: it }, it.id)) })] })] });
};
const Song = ({ artist, album, song }) => {
    const { position, queue } = useStore(store$1);
    const { format, bitrate } = useStore(store$2);
    const downloadQueueState = useStore(store$3);
    const cache = useLocalCache();
    const [cachedSong, setCachedSong] = react_8();
    const [inDownloadQueue, setInDownloadQueue] = react_8(false);
    const [downloadRequested, setDownloadRequested] = react_8(false);
    react_7(() => {
        cache && (async () => {
            const audioUrl = NetworkService.baseurl + '/artists/' + artist.id + '/' + album.id + '/' + song.id + '/' + format + '/' + bitrate;
            const cachedSong = await cache.songByUrl(audioUrl);
            setCachedSong(cachedSong);
        })();
    }, [cache, inDownloadQueue]);
    react_7(() => {
        if (cachedSong)
            return;
        const queued = !!downloadQueueState.queue.find(it => it.artist === artist.name && it.album === album.name && it.song === song.title);
        setInDownloadQueue(queued);
        queued && setDownloadRequested(queued);
    }, [cachedSong, downloadQueueState.queue]);
    const isCurrentSong = queue[position]?.songId === song.id;
    return jsxRuntime_2(FlexRow, { className: u `padding:5px;.MaterialIcon{font-size:16px;color:gray;}`, children: [jsxRuntime_2(FlexRow, { className: u `flex-grow:1;margin-right:20px;cursor:pointer;`, onClick: e => {
                    play(album.songs.map(it => ({
                        artistId: artist.id,
                        albumId: album.id,
                        songId: it.id
                    })), album.songs.findIndex(it => it.id === song.id));
                }, children: [jsxRuntime_2("span", { className: u `width:20px;text-align:right;margin-right:10px;`, children: [song.track, "."] }), jsxRuntime_1("span", { className: u `display:inline-flex;flex-basis:1px;flex-grow:1;color:${isCurrentSong ? '#c02727' :'black'};font-weight:${isCurrentSong ? 'bold' :'normal'};`, children: song.title })] }), !!cachedSong && downloadRequested && jsxRuntime_1("span", { className: u `display:inline-flex;flex-flow:row;align-items:center;justify-content:center;width:24px;height:24px;`, children: jsxRuntime_1(MaterialIcon, { icon: 'download_done' }) }), !cachedSong && jsxRuntime_1(Button, { flat: true, round: true, disabled: inDownloadQueue, className: u `min-width:24px !important;min-height:24px;`, onClick: e => {
                    download(NetworkService.baseurl + '/artists/' + artist.id + '/' + album.id + '/' + song.id + '/' + format + '/' + bitrate, artist.name, album.name, song.title, format, bitrate);
                }, children: jsxRuntime_1(MaterialIcon, { icon: 'download_for_offline' }) })] });
};

export { Library as default };
