import { jsxs as jsxRuntime_2, jsx as jsxRuntime_1 } from 'preact/jsx-runtime';
import { useIsShownOnScreen, useImageBlobUrl } from '../utils/Hooks.js';
import { useState as react_8 } from 'preact/compat';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';
import { classNames } from '../../node_modules/react-ui-basics/Tools.js';
import MaterialIcon from '../../node_modules/react-ui-basics/MaterialIcon.js';
import NetworkService from '../services/NetworkService.js';

const AlbumCoverStyles = u `&.AlbumCover{height:150px;display:flex;justify-content:center;align-items:center;opacity:0;transition:opacity ease 200ms;&.shown{opacity:1;}img{max-width:100%;max-height:150px;border-radius:4px;}.MaterialIcon{font-size:80px;}&.mobile{height:300px;img{width:100%;max-height:300px;max-width:300px;margin-left:auto;margin-right:auto;margin-bottom:20px;}}&.small{height:75px;img{border-radius:0;max-width:75px;max-height:75px;min-width:75px;min-height:75px;}}}`;
const AlbumCover = ({ artistId, album, className, forceShow }) => {
    const [ref, setRef] = react_8(null);
    const isShown = useIsShownOnScreen(ref) || !!forceShow;
    const src = album?.coverPath ? NetworkService.baseurl + '/artists/' + artistId + '/' + album.id + '/' + album.coverPath : null;
    const imageBlobUrl = useImageBlobUrl(src, isShown);
    return jsxRuntime_2("div", { ref: setRef, className: classNames('AlbumCover', AlbumCoverStyles, imageBlobUrl && 'shown', className), children: [imageBlobUrl && jsxRuntime_1("img", { src: imageBlobUrl, alt: album?.name }), !src && jsxRuntime_1(MaterialIcon, { icon: 'album' })] });
};

export { AlbumCover as default };
