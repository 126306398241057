import { fetch } from './HttpClient.js';

const origin = window.location.origin;
const baseurl = (origin.indexOf('localhost') !== -1 || origin.indexOf('127.0.0.1') !== -1) ? 'http://localhost:8080' :
    (origin.indexOf('192.168.0.147') !== -1 ? 'http://192.168.0.147:8080' : origin);
const variablePatter = /\{(\w+)\}/g;
const createUrlMaker = (template, deleteVars = false) => {
    let parts = [];
    let variables = [];
    let find;
    let prevIndex = 0;
    while ((find = variablePatter.exec(template)) !== null) {
        variables.push(find[1]);
        parts.push(template.substring(prevIndex, find.index));
        prevIndex = find.index + find[0].length;
    }
    if (prevIndex === 0)
        return () => template;
    parts.push(template.substring(prevIndex, template.length));
    const m = parts;
    const v = variables;
    return function (params) {
        const length = Math.max(m.length, v.length);
        let s = '';
        for (let i = 0; i < length; i++) {
            if (m[i] !== null)
                s += m[i];
            let param = params[v[i]];
            if (params && v[i] !== null && param != null) {
                s += encodeURIComponent(param);
                if (deleteVars)
                    delete params[v[i]];
            }
        }
        return s;
    };
};
const lazy = (f, ...args) => {
    let result;
    return function () {
        if (!result)
            result = f.apply(null, args);
        return result.apply(null, arguments);
    };
};
const createGET = (template) => {
    let urlMaker = lazy(createUrlMaker, template, true);
    return async (params) => fetch(`${baseurl}${urlMaker(params)}`, { params });
};
const createPOST = (template) => {
    let urlMaker = lazy(createUrlMaker, template);
    return async (params) => fetch(`${baseurl}${urlMaker(params)}`, { params, method: "POST" });
};
const createDELETE = (template) => {
    let urlMaker = lazy(createUrlMaker, template, true);
    return async (params) => fetch(`${baseurl}${urlMaker(params)}`, { params, method: "DELETE" });
};
const createMultipart = (template) => {
    let urlMaker = lazy(createUrlMaker, template);
    return async (params, options) => {
        let url = `${baseurl}${urlMaker(params)}`;
        return fetch(url, { params, method: "POST", multipart: true, ...options });
    };
};
var NetworkService = {
    baseurl,
    //generated endpoints start
    getAlbumCover: createGET('/artists/{artistIdOrPath}/{albumIdOrPath}/cover.jpg'),
    getArtist: createGET('/artists/{id}'),
    getArtists: createGET('/artists'),
    getSong: createGET('/artists/{artistId}/{albumName}/{trackNumber}'),
    getSongConverted: createGET('/artists/{artistId}/{albumIdOrName}/{songIdOrTrackNumber}/{format}/{bitrate}'),
    getSongConvertedStreamed: createGET('/artists/{artistId}/{albumIdOrName}/{songIdOrTrackNumber}/{format}/{bitrate}/stream'),
    createAlbum: createPOST('/artists/{artistId}/album'),
    createArtist: createPOST('/artists/'),
    mergeAlbums: createPOST('/artists/{artistId}/{intoAlbumId}'),
    moveAlbum: createPOST('/artists/{artistId}/{albumId}/moveTo/{toArtistId}'),
    updateArtist: createPOST('/artists/{id}'),
    uploadCoverArt: createMultipart('/artists/{artistId}/{albumId}/cover'),
    deleteAlbum: createDELETE('/artists/{artistId}/{albumId}'),
    deleteArtist: createDELETE('/artists/{id}'),
    deleteSong: createDELETE('/artists/{artistId}/{albumId}/{songId}'),
    isLoginRequired: createGET('/login/required'),
    login: createPOST('/login'),
    upload: createMultipart('/upload'),
    //generated endpoints end
};
var AudioFormat;
(function (AudioFormat) {
    AudioFormat["MP3"] = "MP3";
    AudioFormat["AAC"] = "AAC";
    AudioFormat["OGG"] = "OGG";
    AudioFormat["OPUS"] = "OPUS";
    AudioFormat["FLAC"] = "FLAC";
    AudioFormat["WAV"] = "WAV";
})(AudioFormat || (AudioFormat = {}));
//generated types end

export { AudioFormat, NetworkService as default };
