import { jsx as jsxRuntime_1, jsxs as jsxRuntime_2 } from 'preact/jsx-runtime';
import Button from '../../node_modules/react-ui-basics/Button.js';
import AutocompleteSelect$1 from '../../node_modules/react-ui-basics/AutocompleteSelect.js';
import { classNames, orNoop } from '../../node_modules/react-ui-basics/Tools.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';
import { SCROLLBAR_MODE_HIDDEN } from '../../node_modules/react-ui-basics/Scrollable.js';
import { pushLocation } from '../../node_modules/react-ui-basics/router/HistoryTools.js';
import { FlexRow } from './SharedComponents.js';
import MaterialIcon from '../../node_modules/react-ui-basics/MaterialIcon.js';

const MenuIcon = () => jsxRuntime_1(Button, { flat: true, round: true, children: jsxRuntime_1("i", { className: "material-icons", children: "more_vert" }) });
const DummyChild = ({ id, label, dataConsumer, onClick }) => {
    orNoop(dataConsumer)(label || id);
    if (id === 'separator')
        return label;
    return jsxRuntime_1("div", { className: `DummyChild`, onClick: onClick, children: label || id });
};
const MoreMenu = ({ className }) => jsxRuntime_1(AutocompleteSelect$1, { className: classNames(u `min-width:0;.popup{right:0;.DummyChild{font-size:14px;color:#818181;}.material-icons{color:#818181;margin-right:4px;}.ItemWrapper{height:unset;&.selected{padding-left:15px;margin-left:-5px;}}}> .selected{border:none;&.nolabel{border:none;}.material-icons{color:#A6A6A6;transition:color 0.2s ease;}}&.active .selected .material-icons{color:#333333;}`, className), scroll: SCROLLBAR_MODE_HIDDEN, value: true, withArrow: false, selectedComponent: MenuIcon, withFilter: false, selectedMode: 'inline', onSelect: v => {
        v === 'settings' && pushLocation('/settings');
        v === 'cache' && pushLocation('/cache');
    }, data: [
        // 'separator',
        'settings',
        'cache',
    ], childComponent: DummyChild, labels: {
        'separator': jsxRuntime_1(FlexRow, { className: u `height:1px;width:100%;background:gray;` }),
        'settings': jsxRuntime_2(FlexRow, { children: [jsxRuntime_1(MaterialIcon, { icon: 'settings' }), "Settings"] }),
        'cache': jsxRuntime_2(FlexRow, { children: [jsxRuntime_1(MaterialIcon, { icon: 'archive' }), "Cache"] }),
    } });

export { MoreMenu as default };
