import { Store } from '../../node_modules/react-ui-basics/store/Store.js';
import { addEventListener, WINDOW } from '../../node_modules/react-ui-basics/Tools.js';

const store = new Store({
    queue: [],
    position: 0,
    offset: 0,
    volume: 1,
    playing: false,
});
const play = (songs, position = 0) => {
    store.set(state => {
        state.queue = songs;
        state.position = position;
        state.playing = true;
        state.offset = 0;
    });
};
const setPlaying = (playing) => {
    store.set(state => {
        state.playing = playing;
    });
    if (!playing)
        saveState();
};
const setPlayingAndOffset = (playing, offset) => {
    store.set(state => {
        state.playing = playing;
        state.offset = offset;
    });
    if (!playing)
        saveState();
};
const setOffset = (offset) => {
    store.set(state => {
        state.offset = offset;
    });
};
const setVolume = (volume) => {
    store.set(state => {
        state.volume = volume;
    });
};
const next = () => {
    store.set(state => {
        state.position++;
        if (state.position >= state.queue.length)
            state.position = 0;
    });
};
const prev = () => {
    store.set(state => {
        state.position--;
        if (state.position < 0)
            state.position = state.queue.length - 1;
    });
};
const saveState = () => {
    localStorage.setItem('playerState', JSON.stringify(store.get()));
};
addEventListener(WINDOW, 'beforeunload', saveState, {});
(() => {
    let savedState = localStorage.getItem('playerState');
    if (savedState) {
        store.set(() => JSON.parse(savedState));
    }
})();

export { store as default, next, play, prev, setOffset, setPlaying, setPlayingAndOffset, setVolume, store };
