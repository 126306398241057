import { Store } from '../../node_modules/react-ui-basics/store/Store.js';
import NetworkService from '../services/NetworkService.js';

const store = new Store({
    tokenValidUntil: 0,
    loginRequired: null
});
const saveSettings = () => {
    localStorage.setItem('login', JSON.stringify(store.get()));
};
const setTokenValidUntil = (tokenValidUntil) => {
    store.set(state => {
        state.tokenValidUntil = tokenValidUntil;
    });
    saveSettings();
};
(async () => {
    let savedState = localStorage.getItem('login');
    if (savedState) {
        store.set(() => JSON.parse(savedState));
    }
    const isLoginRequired = await NetworkService.isLoginRequired();
    store.set((state) => {
        state.loginRequired = isLoginRequired.required;
        if (!isLoginRequired.tokenValid) {
            state.tokenValidUntil = 0;
        }
    });
})();

export { store as default, setTokenValidUntil, store };
