import { useState as react_8, useRef as react_12, useEffect as react_7 } from 'preact/compat';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';
import { store, remove } from '../stores/DownloadQueueStore.js';
import { useLocalCache } from '../services/LocalCacheService.js';
import { addEventListener, clearInterval, removeEventListener, setInterval } from '../../node_modules/react-ui-basics/Tools.js';

const load = (url, localCache, artist, album, name, setAudio, onFinish) => {
    function concat(arrays) {
        let totalLength = arrays.reduce((acc, value) => acc + value.length, 0);
        if (!arrays.length)
            return null;
        let result = new Uint8Array(totalLength);
        let length = 0;
        for (let array of arrays) {
            result.set(array, length);
            length += array.length;
        }
        return result;
    }
    console.log('trying to fetch stream');
    fetch(url + '/stream', {
        credentials: 'include'
    })
        .then((response) => {
        const reader = response.body.getReader();
        const contentType = response.headers.get('Content-Type');
        const arrays = [];
        let song = {
            url,
            name,
            album,
            artist,
            type: contentType,
            size: 0,
            dataId: 0,
            timesPlayed: 0,
            dateAdded: new Date().getTime(),
        };
        let sourceBuffer;
        let bufferPosition = 0;
        let isWaiting = false;
        let isFinished = false;
        let totalAdded = 0;
        let decode;
        if (setAudio) {
            setAudio(song, null, audio => {
                const MS = window['MediaSource'] || window['ManagedMediaSource'];
                // @ts-ignore
                console.log('MediaSource: ', MS, MS.isTypeSupported(song.type));
                // @ts-ignore
                if (!MS.isTypeSupported(song.type)) {
                    audio.src = url + '/stream';
                    return;
                }
                // @ts-ignore
                const mediaSource = new MS();
                let updateInterval = null;
                let detached = false;
                console.log('mediaSource', mediaSource);
                mediaSource.onstartstreaming = (e) => {
                    console.log('mediaSource.onstartstreaming', e);
                };
                mediaSource.onendstreaming = (e) => {
                    console.log('mediaSource.onendstreaming', e);
                };
                mediaSource.onsourceopen = (e) => {
                    console.log('mediaSource.onsourceopen', e);
                };
                addEventListener(mediaSource, 'sourceopen', () => {
                    console.log('mediaSource.sourceopen');
                    sourceBuffer = mediaSource.addSourceBuffer(contentType);
                    decode = () => {
                        if (detached)
                            return;
                        if (bufferPosition < arrays.length) {
                            if (!sourceBuffer.buffered.length || sourceBuffer.buffered.end(0) - audio.currentTime < 10) {
                                let buffer = arrays[bufferPosition++];
                                totalAdded += buffer.length;
                                sourceBuffer.appendBuffer(buffer);
                                clearInterval(updateInterval);
                            }
                            else {
                                clearInterval(updateInterval);
                                updateInterval = setInterval(decode, 1000);
                            }
                        }
                        else if (isFinished) {
                            mediaSource.endOfStream();
                        }
                        else {
                            isWaiting = true;
                        }
                    };
                    let pauseListener = () => {
                        clearInterval(updateInterval);
                    };
                    let playListener = () => {
                        decode();
                    };
                    addEventListener(audio, 'pause', pauseListener);
                    addEventListener(audio, 'play', playListener);
                    mediaSource.onsourceclose = () => {
                        console.log('mediaSource.onsourceclose');
                        detached = true;
                        clearInterval(updateInterval);
                        removeEventListener(audio, 'pause', pauseListener);
                        removeEventListener(audio, 'play', pauseListener);
                    };
                    isWaiting = true;
                    addEventListener(sourceBuffer, 'updateend', decode);
                });
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                console.log('isSafari', isSafari);
                if (isSafari) {
                    let sourceElement = document.createElement('source');
                    audio.childNodes[0] && audio.removeChild(audio.childNodes[0]);
                    audio.appendChild(sourceElement);
                    sourceElement.src = audio.dataset.objectUrl = URL.createObjectURL(mediaSource);
                    sourceElement.type = song.type;
                }
                else {
                    audio.src = audio.dataset.objectUrl = URL.createObjectURL(mediaSource);
                }
                // audio.src = audio.dataset.objectUrl = URL.createObjectURL(mediaSource);
                // console.log('audio.src = audio.dataset.objectUrl = URL.createObjectURL(mediaSource)', audio.src)
                audio.play().catch(e => {
                    console.error("qwe play failed", e);
                });
                // setTimeout(()=>{
                //     audio.src = audio.dataset.objectUrl = URL.createObjectURL(mediaSource);
                //     console.log('audio.src = audio.dataset.objectUrl = URL.createObjectURL(mediaSource)', audio.src)
                //     audio.play().catch(e=>{
                //         console.error("qwe play failed", e)
                //     })
                // },5000)
            });
        }
        function pump() {
            reader.read().then(({ done, value }) => {
                // console.log('on chunk', value?.length, 'is last:', done, bufferPosition, arrays.length)
                if (value) {
                    arrays.push(value);
                    if (isWaiting) {
                        isWaiting = false;
                        decode();
                    }
                }
                if (done) {
                    isFinished = true;
                    if (isWaiting) {
                        decode();
                    }
                    const data = concat(arrays);
                    // console.log('done', data)
                    song.size = data.byteLength;
                    localCache.add(song, data);
                    onFinish && onFinish();
                    return;
                }
                pump();
            });
        }
        pump();
    })
        .catch(e => {
        console.log(e);
    });
    // var request = new XMLHttpRequest();
    // request.open('GET', url, true);
    // request.responseType = 'arraybuffer';
    //
    // request.onload = () => {
    //     let audioData = request.response;
    //     const responsetext = (!request.responseType || request.responseType ==='text') && request.responseText;
    //     if (request.status != 200 || Number(request.getResponseHeader('Content-Length')) === 0) {
    //         console.error(url, request.status, responsetext)
    //         if (!isRetrying || request.status === 503) {
    //             load(url, setAudio, localCache, artist, album, name, true)
    //         }
    //         return
    //     }
    //
    //     const contentType = request.getResponseHeader('Content-Type');
    //     const data: ArrayBuffer = audioData.slice();
    //
    //     let song: Song = {
    //         url,
    //         name,
    //         album,
    //         artist,
    //         type: contentType,
    //         size: data.byteLength,
    //         dataId: 0,
    //         timesPlayed: 0,
    //         dateAdded: new Date().getTime(),
    //     };
    //     localCache.add(song, data)
    //     setAudio(song, data)
    // }
    // request.send();
};
const MAX_PARALLEL_DOWNLOADS = 4;
const DownloadQueue = ({}) => {
    const localCache = useLocalCache();
    const { queue } = useStore(store);
    const [downloading, setDownloading] = react_8(0);
    const downloadingRef = react_12();
    downloadingRef.current = downloading;
    react_7(() => {
        if (!localCache)
            return;
        if (downloading == MAX_PARALLEL_DOWNLOADS)
            return;
        if (queue.length <= downloading)
            return;
        const task = queue[downloading];
        setDownloading(downloading + 1);
        load(task.url, localCache, task.artist, task.album, task.song, (song, data, attachAudio) => {
            task.onData && task.onData(song, data, attachAudio);
        }, () => {
            setDownloading(downloadingRef.current - 1);
            remove(task);
        });
    }, [localCache, queue, downloading]);
    return null;
};

export { DownloadQueue as default };
