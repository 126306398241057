import { jsx as jsxRuntime_1 } from 'preact/jsx-runtime';
import reactDom from 'preact/compat';
import App from './components/App.js';
import { setup as m } from '../node_modules/goober/dist/goober.esm.js';
import object from './BuildInfo.js';

m(jsxRuntime_1);
window['buildInfo'] = object;
reactDom.render(jsxRuntime_1(App, {}), document.getElementById('root'));
const getRidOfAllServiceWorkers = async () => {
    const registry = await navigator.serviceWorker.getRegistrations();
    for (const entry of registry) {
        entry.unregister();
    }
};
if (window.location.hostname === 'localhost') {
    getRidOfAllServiceWorkers();
}
else {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js');
    }
}
