import { Store } from '../../node_modules/react-ui-basics/store/Store.js';
import { isDifferent } from '../../node_modules/react-ui-basics/Tools.js';

const store = new Store({
    ids: [],
    map: {},
});
const setAll = (items) => {
    store.set(state => {
        state.ids = items.map(it => it.id);
        state.map = items.reduce((m, it) => (m[it.id] = it, m), {});
        return state;
    });
};
const set = (item) => {
    store.set(state => {
        if (!state.ids.includes(item.id))
            state.ids.push(item.id);
        if (isDifferent(item, state.map[item.id]))
            state.map[item.id] = item;
        return state;
    });
};

export { store as default, set, setAll, store };
