import { jsx as jsxRuntime_1 } from 'preact/jsx-runtime';
import { useLocalCache } from '../services/LocalCacheService.js';
import { useState as react_8, useEffect as react_7 } from 'preact/compat';
import Table from '../../node_modules/react-ui-basics/Table.js';
import Size from '../../node_modules/react-ui-basics/Size.js';
import Button from '../../node_modules/react-ui-basics/Button.js';
import { Comparators } from '../../node_modules/react-ui-basics/Tools.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';

const CacheStats = ({}) => {
    const localCache = useLocalCache();
    const [refreshed, setRefreshed] = react_8(new Date().getTime());
    const [songs, setSongs] = react_8([]);
    const [albums, setAlbums] = react_8([]);
    react_7(() => {
        localCache && (async () => {
            const songs = await localCache.songs();
            setSongs(songs);
            console.log(songs);
            const albums = {};
            songs.forEach(it => {
                let albumKey = it.artist + ' - ' + it.album;
                if (!albums[albumKey])
                    albums[albumKey] = {
                        name: it.album,
                        artist: it.artist,
                        songs: []
                    };
                const album = albums[albumKey];
                album.songs.push(it);
            });
            setAlbums(Object.values(albums));
        })();
    }, [localCache, refreshed]);
    return jsxRuntime_1("div", { className: u `padding:60px 20px 20px;`, children: jsxRuntime_1(Table, { sortBy: 'name', data: albums, columns: [
                {
                    field: 'name',
                    header: 'Name',
                    sortable: true,
                },
                {
                    field: 'songs',
                    header: 'Tracks',
                    sortable: false,
                    formatter: ((it) => String(it.length))
                },
                {
                    field: 'songs',
                    header: 'Size',
                    sortable: true,
                    formatter: ((it) => jsxRuntime_1(Size, { value: it.reduce((total, it) => {
                            total += it.size;
                            return total;
                        }, 0) })),
                    comparator: Comparators.of(it => it.songs.reduce((total, it) => {
                        total += it.size;
                        return total;
                    }, 0), Comparators.SORT_ASC, albums)
                },
                {
                    field: 'name',
                    header: '',
                    sortable: false,
                    formatter: ((name, it) => jsxRuntime_1(Button, { className: 'red', onClick: async () => {
                            for (let i = 0; i < it.songs.length; i++) {
                                await localCache.deleteSong(it.songs[i]);
                            }
                            setRefreshed(new Date().getTime());
                        }, children: "delete" }))
                },
            ] }) });
};

export { CacheStats as default };
