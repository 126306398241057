import DB from '../utils/DB.js';
import { Store, useStore } from '../../node_modules/react-ui-basics/store/Store.js';

const SONGS_STORE_NAME = "songs";
const SONG_DATA_STORE_NAME = "song_data";
const WEB_CACHE_STORE_NAME = "web_cache";
class SongLocalCacheDB extends DB {
    async songByUrl(url) {
        return this.trRO(SONGS_STORE_NAME)
            .objectStore(SONGS_STORE_NAME)
            .index('url')
            .get(url)
            .asPromise();
    }
    async songs() {
        return this.trRO(SONGS_STORE_NAME)
            .objectStore(SONGS_STORE_NAME)
            .getAll()
            .asPromise();
    }
    async deleteSong(song) {
        let tr = this.trRW(SONGS_STORE_NAME, SONG_DATA_STORE_NAME);
        const songId = await tr.objectStore(SONGS_STORE_NAME)
            .index('url')
            .getKey(song.url)
            .asPromise();
        await tr.objectStore(SONGS_STORE_NAME)
            .delete(songId)
            .asPromise();
        await tr.objectStore(SONG_DATA_STORE_NAME)
            .delete(song.dataId)
            .asPromise();
    }
    async deleteUnusedSongData() {
        let tr = this.trRW(SONGS_STORE_NAME, SONG_DATA_STORE_NAME);
        const songs = await tr.objectStore(SONGS_STORE_NAME)
            .getAll()
            .asPromise();
        const songDataKeys = await tr.objectStore(SONG_DATA_STORE_NAME)
            .getAllKeys()
            .asPromise();
        var usedDataKeys = songs.reduce((set, song) => {
            set.add(song.dataId);
            return set;
        }, new Set());
        for (const id of songDataKeys) {
            if (!usedDataKeys.has(id)) {
                await tr.objectStore(SONG_DATA_STORE_NAME)
                    .delete(id)
                    .asPromise();
            }
        }
    }
    async songData(id) {
        return this.trRO(SONG_DATA_STORE_NAME)
            .objectStore(SONG_DATA_STORE_NAME)
            .get(id)
            .asPromise();
    }
    async add(song, data) {
        const tr = this.trRW(SONGS_STORE_NAME, SONG_DATA_STORE_NAME);
        song.dataId = await tr.objectStore(SONG_DATA_STORE_NAME)
            .add({ data })
            .asPromise();
        return tr.objectStore(SONGS_STORE_NAME)
            .add(song)
            .asPromise();
    }
    async addWebCacheEntry(entry) {
        const tr = this.trRW(WEB_CACHE_STORE_NAME);
        return await tr.objectStore(WEB_CACHE_STORE_NAME)
            .add(entry)
            .asPromise();
    }
    async getWebCacheEntry(url) {
        return this.trRO(WEB_CACHE_STORE_NAME)
            .objectStore(WEB_CACHE_STORE_NAME)
            .index('url')
            .get(url)
            .asPromise();
    }
}
const db = new SongLocalCacheDB("localCache", [
    {
        version: () => 1,
        name: () => 'init',
        execute: (tr, db) => {
            db.createObjectStore(SONGS_STORE_NAME, { autoIncrement: true });
        }
    },
    {
        version: () => 2,
        name: () => 'creating indexes',
        execute: async (tr, db, wrapper) => {
            const store = tr.objectStore(SONGS_STORE_NAME);
            store.createIndex("url", "url", { unique: true });
            store.createIndex("artist", "artist", { unique: false });
            store.createIndex("album", "album", { unique: false });
            store.createIndex("size", "size", { unique: false });
        }
    },
    {
        version: () => 3,
        name: () => 'adding song_data',
        execute: async (tr, db, wrapper) => {
            db.createObjectStore(SONG_DATA_STORE_NAME, { autoIncrement: true });
        }
    },
    {
        version: () => 4,
        name: () => 'adding web_cache',
        execute: async (tr, db, wrapper) => {
            db.createObjectStore(WEB_CACHE_STORE_NAME, { autoIncrement: true });
            const store = tr.objectStore(WEB_CACHE_STORE_NAME);
            store.createIndex("url", "url", { unique: true });
        }
    },
]);
const store = new Store({ ready: false });
(async () => {
    try {
        await db.open();
        store.set(state => {
            state.ready = true;
        });
    }
    catch (e) {
        console.error(e);
    }
})();
const useLocalCache = () => {
    const isReady = useStore(store).ready;
    return isReady ? db : null;
};

export { SongLocalCacheDB, useLocalCache };
