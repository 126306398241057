import { styled as j, css as u } from '../../node_modules/goober/dist/goober.esm.js';

const FlexRow = j('div') `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;
const FlexColumn = j('div') `
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;
const smallIconButtonCss = u `color:darkgray;min-width:24px !important;min-height:24px;.material-icons{font-size:16px;}`;

export { FlexColumn, FlexRow, smallIconButtonCss };
