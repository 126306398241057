import { useState as react_8, useEffect as react_7, useReducer as react_11, useRef as react_12, useMemo as react_9 } from 'preact/compat';
import { WINDOW } from '../../node_modules/react-ui-basics/Tools.js';
import { useLocalCache } from '../services/LocalCacheService.js';
import { store, add } from '../stores/BlobStore.js';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';

const useWindowSize = () => {
    const [windowsSize, setWindowSize] = react_8({ width: WINDOW.innerWidth, height: WINDOW.innerHeight });
    react_7(() => {
        const listener = () => {
            setWindowSize({ width: WINDOW.innerWidth, height: WINDOW.innerHeight });
        };
        WINDOW.addEventListener('resize', listener);
        return () => WINDOW.removeEventListener('resize', listener);
    }, []);
    return windowsSize;
};
const useIsSafari = () => {
    const [isSafari] = react_8(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent));
    return isSafari;
};
const useWebCache = (url) => {
    const localCache = useLocalCache();
    const [_, forceUpdate] = react_11(x => x + 1, 0);
    const valueRef = react_12(null);
    react_7(() => {
        valueRef.current = null;
        let canceled = false;
        (async () => {
            if (!localCache)
                return;
            if (!url)
                return;
            let cacheEntry = await localCache.getWebCacheEntry(url);
            if (canceled)
                return;
            if (cacheEntry) {
                valueRef.current = cacheEntry;
                forceUpdate();
            }
            try {
                const response = await fetch(url, {
                    credentials: 'include',
                    headers: {
                        'If-None-Match': cacheEntry?.etag || '',
                    }
                });
                if (response.status == 200) {
                    const etag = response.headers.get("ETag");
                    const blob = await response.blob();
                    const data = await blob.arrayBuffer();
                    cacheEntry = {
                        url,
                        etag,
                        data
                    };
                    if (etag)
                        localCache.addWebCacheEntry(cacheEntry);
                    if (canceled)
                        return;
                    valueRef.current = cacheEntry;
                    forceUpdate();
                }
                else if (response.status == 304) {
                    return;
                }
                else {
                    console.log("unexpected response", url, response.status, response.text());
                }
            }
            catch (e) {
                console.error(`Failed to load ${url}`, e);
            }
        })();
        return () => {
            canceled = true;
        };
    }, [url, localCache]);
    return valueRef.current?.data;
};
const useIsShownOnScreen = (element) => {
    const [isShown, setIsShown] = react_8(false);
    const observer = react_9(() => new IntersectionObserver(([entry]) => {
        setIsShown(entry.isIntersecting);
    }), []);
    react_7(() => {
        if (!element)
            return;
        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [element, observer]);
    return isShown;
};
const useImageBlobUrl = (src, doLoad = true) => {
    const blobUrl = useStore(store, it => it[src]);
    const buffer = useWebCache(!blobUrl && doLoad ? src : null);
    react_7(() => {
        if (!buffer || !src)
            return;
        const blob = new Blob([buffer]);
        let objectURL = URL.createObjectURL(blob);
        add(src, objectURL);
    }, [buffer, src]);
    return blobUrl;
};

export { useImageBlobUrl, useIsSafari, useIsShownOnScreen, useWebCache, useWindowSize };
