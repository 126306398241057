import { jsxs as jsxRuntime_2, jsx as jsxRuntime_1 } from 'preact/jsx-runtime';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';
import { store, setFormat, setBitrate } from '../stores/SettingsStore.js';
import { classNames } from '../../node_modules/react-ui-basics/Tools.js';
import { SCROLLBAR_MODE_HIDDEN } from '../../node_modules/react-ui-basics/Scrollable.js';
import AutocompleteSelect$1 from '../../node_modules/react-ui-basics/AutocompleteSelect.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';

const Settings = ({}) => {
    const { format, bitrate } = useStore(store);
    return jsxRuntime_2("div", { className: u `padding:40px 20px 20px;`, children: [jsxRuntime_1("h2", { children: "Settings" }), jsxRuntime_1(AutocompleteSelect$1, { className: classNames(), scroll: SCROLLBAR_MODE_HIDDEN, value: format, withArrow: false, withFilter: false, selectedMode: 'inline', onSelect: setFormat, data: [
                    'MP3', 'OGG', 'AAC', 'FLAC', 'OPUS'
                ] }), jsxRuntime_1(AutocompleteSelect$1, { className: classNames(), scroll: SCROLLBAR_MODE_HIDDEN, value: bitrate, withArrow: false, withFilter: false, selectedMode: 'inline', onSelect: setBitrate, data: [
                    '128', '160', '192', '224', '256', '320'
                ] })] });
};

export { Settings as default };
