import { jsx as jsxRuntime_1, jsxs as jsxRuntime_2 } from 'preact/jsx-runtime';
import { useEffect as react_7 } from 'preact/compat';
import { classNames } from '../../node_modules/react-ui-basics/Tools.js';
import Route$1 from '../../node_modules/react-ui-basics/router/Route.js';
import LibraryEditor from './lazy/LibraryEditor.js';
import Dialog from './Dialog.js';
import Library from './Library.js';
import Player from './Player.js';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';
import { setAll, set, store } from '../stores/ArtistsStore.js';
import { store as store$1 } from '../stores/PlayerStore.js';
import { store as store$2 } from '../stores/AuthenticationStore.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';
import NetworkService from '../services/NetworkService.js';
import CacheStats from './CacheStats.js';
import Settings from './Settings.js';
import MoreMenu from './MoreMenu.js';
import DownloadQueue from './DownloadQueue.js';
import Button from '../../node_modules/react-ui-basics/Button.js';
import MaterialIcon from '../../node_modules/react-ui-basics/MaterialIcon.js';
import { useImageBlobUrl, useWindowSize } from '../utils/Hooks.js';
import { useLocalCache } from '../services/LocalCacheService.js';
import LoginForm from './LoginForm.js';

var App = () => {
    const artistsStore = useStore(store);
    const queue = useStore(store$1, ({ queue }) => queue);
    const position = useStore(store$1, ({ position }) => position);
    const playing = useStore(store$1, ({ playing }) => playing);
    const queuedSong = queue[position];
    const artist = artistsStore.map[queuedSong?.artistId];
    const album = artist?.albums?.find(it => it.id === queuedSong?.albumId);
    const backgroundSrc = playing && album && `${NetworkService.baseurl}/artists/${artist.id}/${album.id}/${album.coverPath}`;
    const coverBackgroundUrl = useImageBlobUrl(backgroundSrc);
    console.log('coverBackground', album, coverBackgroundUrl, backgroundSrc);
    const coverBackground = coverBackgroundUrl && u `background-image:url('${coverBackgroundUrl}');`;
    const windowSize = useWindowSize();
    const localCacheDB = useLocalCache();
    react_7(() => {
        localCacheDB && localCacheDB.deleteUnusedSongData();
    }, [localCacheDB]);
    react_7(() => {
        navigator.serviceWorker && (navigator.serviceWorker.onmessage = (event) => {
            console.log(event);
            const data = event.data;
            if (data.type === 'FETCH') {
                let url = new URL(data.url);
                if (url.pathname === '/artists') {
                    setAll(data.data);
                }
                else if (/\/artists\/([0-9]+)/.exec(url.pathname)) {
                    set(data.data);
                }
            }
        });
    }, []);
    const authenticationState = useStore(store$2);
    if (authenticationState.loginRequired === null)
        return null;
    if (authenticationState.loginRequired && authenticationState.tokenValidUntil < new Date().getTime())
        return jsxRuntime_1(LoginForm, {});
    return (jsxRuntime_1("div", { className: classNames("App", u `background:white;background-size:cover;background-position-x:center;background-position-y:center;max-width:900px;margin-left:auto;margin-right:auto;`, coverBackground), children: jsxRuntime_2("div", { className: u `box-sizing:border-box;min-height:${windowSize.height}px;background:rgba(255, 255, 255, 0.45);backdrop-filter:blur(40px);-webkit-backdrop-filter:blur(40px);`, children: [jsxRuntime_1(Route$1, { path: '/*', children: window.location.pathname.length > 1 && jsxRuntime_1(Button, { className: classNames('', u `position:absolute;top:10px;left:10px;z-index:1;.MaterialIcon{font-size:20px;color:gray;}`), flat: true, round: true, onClick: e => {
                            window.history.back();
                        }, children: jsxRuntime_1(MaterialIcon, { icon: 'chevron_left' }) }) }), jsxRuntime_1(MoreMenu, { className: u `position:absolute;z-index:10;right:10px;top:10px;` }), jsxRuntime_1(Route$1, { path: ["/edit/artists/:artistId?/:album?", "/edit/albums"], children: jsxRuntime_1(LibraryEditor, { album: null, artistId: null }) }), jsxRuntime_1(Route$1, { path: "/cache", children: jsxRuntime_1(CacheStats, {}) }), jsxRuntime_1(Route$1, { path: "/settings", children: jsxRuntime_1(Settings, {}) }), jsxRuntime_1(Route$1, { path: ["/:artistId(^[0-9]+$)?/:album?", "/", "/albums"], children: jsxRuntime_1(Library, { artistId: null, album: null }) }), queue.length > 0 && jsxRuntime_1(Player, {}), jsxRuntime_1(Dialog, {}), jsxRuntime_1(DownloadQueue, {})] }) }));
};

export { App as default };
