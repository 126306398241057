import { Store } from '../../node_modules/react-ui-basics/store/Store.js';

const store = new Store({
    format: 'MP3',
    bitrate: 192,
});
const saveSettings = () => {
    localStorage.setItem('settings', JSON.stringify(store.get()));
};
const setFormat = (format) => {
    store.set(state => {
        state.format = format;
    });
    saveSettings();
};
const setBitrate = (bitrate) => {
    store.set(state => {
        state.bitrate = bitrate;
    });
    saveSettings();
};
(() => {
    let savedState = localStorage.getItem('settings');
    if (savedState) {
        store.set(() => JSON.parse(savedState));
    }
})();

export { store as default, setBitrate, setFormat, store };
