import { Store } from '../../node_modules/react-ui-basics/store/Store.js';

const store = new Store({
    show: false,
    accept: "",
    cancel: "",
    title: "",
    description: "",
    buttons: null,
    onAccept: () => {
    },
    onCancel: () => {
    },
});
const show = ({ title, description, accept, onAccept, cancel, onCancel, buttons }) => {
    store.set(state => Object.assign(state, {
        show: true,
        accept,
        cancel,
        onAccept,
        title,
        description,
        buttons,
        onCancel: onCancel && (() => {
            onCancel && onCancel();
            hide();
        })
    }));
};
const hide = () => {
    store.set(state => Object.assign(state, {
        show: false,
        accept: null,
        cancel: null,
        onAccept: null,
        title: null,
        description: null,
        buttons: null,
        onCancel: null
    }));
};

export { store as default, hide, show, store };
