import { useState as react_8, useEffect as react_7, createElement as react_4 } from 'preact/compat';

const Lazy = ({ promise, ...other }) => {
    const [component, setComponent] = react_8();
    react_7(() => {
        promise.then(({ default: component }) => setComponent({ c: component }));
    }, []);
    if (!component)
        return null;
    return react_4(component.c, other);
};

export { Lazy as default };
