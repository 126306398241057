import { jsx as jsxRuntime_1, jsxs as jsxRuntime_2 } from 'preact/jsx-runtime';
import ProgressBar from './ProgressBar.js';
import Button from '../../node_modules/react-ui-basics/Button.js';
import { useState as react_8, useEffect as react_7 } from 'preact/compat';
import { useLocalCache } from '../services/LocalCacheService.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';
import { formatDuration } from '../utils/Helpers.js';
import { FlexRow } from './SharedComponents.js';
import MaterialIcon from '../../node_modules/react-ui-basics/MaterialIcon.js';
import { classNames } from '../../node_modules/react-ui-basics/Tools.js';
import { useStore } from '../../node_modules/react-ui-basics/store/Store.js';
import { store } from '../stores/ArtistsStore.js';
import { store as store$2, setPlayingAndOffset, next, setPlaying, prev, setOffset, setVolume } from '../stores/PlayerStore.js';
import { store as store$1 } from '../stores/SettingsStore.js';
import NetworkService from '../services/NetworkService.js';
import { store as store$4 } from '../stores/WindowActiveStore.js';
import { useIsSafari } from '../utils/Hooks.js';
import { download } from '../stores/DownloadQueueStore.js';
import { pushLocation } from '../../node_modules/react-ui-basics/router/HistoryTools.js';
import { store as store$3 } from '../stores/BlobStore.js';

const Player = ({}) => {
    const localCache = useLocalCache();
    const isSafari = useIsSafari();
    const artistsStore = useStore(store);
    const { format, bitrate } = useStore(store$1);
    const { playing, position, queue, offset, volume } = useStore(store$2);
    const [progress, setProgress] = react_8(0);
    const queuedSong = queue[position];
    const artist = artistsStore.map[queuedSong?.artistId];
    const album = artist?.albums?.find(it => it.id === queuedSong?.albumId);
    const song = album?.songs?.find(it => it.id === queuedSong?.songId);
    let duration = song?.duration / 1000;
    if (Number.isNaN(duration))
        duration = 0;
    const [audio] = react_8(() => new Audio());
    const [silence] = react_8(() => new Audio());
    react_7(() => {
        if (!silence)
            return;
        silence.src = '/static/silence.mp3';
        silence.loop = true;
    }, [silence]);
    react_7(() => {
        let updater;
        audio.addEventListener('pause', (e) => {
            const { queue, position } = store$2.get();
            const queuedSong = queue[position];
            const artist = store.get().map[queuedSong?.artistId];
            const album = artist?.albums?.find(it => it.id === queuedSong?.albumId);
            const song = album?.songs?.find(it => it.id === queuedSong?.songId);
            const duration = song?.duration / 1000;
            // console.log(new Date().toISOString(), 'on pause', e, audio.currentTime, duration, duration - audio.currentTime)
            // PlayerStore.setPlaying(false)
            // clearInterval(updater)
            if (duration - audio.currentTime > 0.01) {
                setPlayingAndOffset(false, audio.currentTime);
                silence.pause();
            }
        });
        audio.addEventListener('ended', (e) => {
            const { queue, position } = store$2.get();
            const queuedSong = queue[position];
            const artist = store.get().map[queuedSong?.artistId];
            const album = artist?.albums?.find(it => it.id === queuedSong?.albumId);
            const song = album?.songs?.find(it => it.id === queuedSong?.songId);
            song?.duration / 1000;
            // console.log(new Date().toISOString(), 'on ended', e, audio.currentTime, duration, duration - audio.currentTime)
            // console.log(new Date().toISOString(), 'PlayerStore.next()')
            next();
        });
        audio.addEventListener('play', (e) => {
            // console.log(new Date().toISOString(), 'on play', e)
            setPlaying(true);
            silence.play(); // otherwise bluetooth headphones makes a pop sound in between tracks
            const { queue, position } = store$2.get();
            const queuedSong = queue[position];
            const artist = store.get().map[queuedSong?.artistId];
            const album = artist?.albums?.find(it => it.id === queuedSong?.albumId);
            const song = album?.songs?.find(it => it.id === queuedSong?.songId);
            const duration = song?.duration / 1000;
            const artwork = store$3.get()[NetworkService.baseurl + '/artists/' + artist.id + '/' + album.id + '/' + album.coverPath];
            if (navigator.mediaSession) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: song.title,
                    album: album.name,
                    artist: artist.name,
                    artwork: !artwork ? [] : [
                        { src: artwork, type: 'image/jpeg' }
                    ]
                });
                // console.log(new Date().toISOString(), 'setPositionState', duration, audio.currentTime)
                navigator.mediaSession.setPositionState({
                    duration,
                    position: audio.currentTime > duration ? 0 : audio.currentTime,
                    playbackRate: 1,
                });
            }
            // if (navigator.mediaSession) {
            //     navigator.mediaSession.metadata = new MediaMetadata({
            //         title: song.title,
            //         album: album.name,
            //         artist: artist.name,
            //         artwork: !album.coverHash ? [] : [
            //             {src: NetworkService.baseurl + '/artists/' + artist.path + '/' + album.path + '/' + album.coverPath, type: 'image/jpeg'}
            //         ]
            //     });
            //     navigator.mediaSession.setPositionState({
            //         duration,
            //         position: audio.currentTime,
            //         playbackRate: 1,
            //
            //     })
            //     navigator.mediaSession.setActionHandler('previoustrack', () => {
            //         PlayerStore.prev()
            //     });
            //
            //     navigator.mediaSession.setActionHandler('nexttrack', () => {
            //         PlayerStore.next()
            //     });
            // }
            // console.log(new Date().toISOString(), 'start updater', duration)
            // if (navigator.mediaSession) {
            //     navigator.mediaSession.setPositionState({duration, position: audio.currentTime, playbackRate: 1})
            // }
            clearInterval(updater);
            const interval = setInterval(() => {
                const playerState = store$2.get();
                if (!playerState.playing) {
                    clearInterval(interval);
                    return;
                }
                const position = audio.currentTime;
                const progress = position / duration * 100;
                if (!store$4.get().hidden)
                    setProgress(Math.min(progress, 100));
                if (progress >= 100) {
                    // audio.pause()
                    clearInterval(interval);
                    // console.log(new Date().toISOString(), 'PlayerStore.next()')
                    // PlayerStore.next()
                }
            }, 1000 / 30);
            // setUpdater(interval)
            updater = interval;
        });
        audio.addEventListener('loadeddata', async (ev) => {
            // console.log(new Date().toISOString(), 'loadeddata', ev)
            const { playing, queue, position } = store$2.get();
            // if (!playing)
            //     return
            try {
                await audio.play();
                const queuedSong = queue[position];
                const artist = store.get().map[queuedSong?.artistId];
                const album = artist?.albums?.find(it => it.id === queuedSong?.albumId);
                const song = album?.songs?.find(it => it.id === queuedSong?.songId);
                const duration = song?.duration / 1000;
                const artwork = store$3.get()[NetworkService.baseurl + '/artists/' + artist.id + '/' + album.id + '/' + album.coverPath];
                if (navigator.mediaSession) {
                    navigator.mediaSession.metadata = new MediaMetadata({
                        title: song.title,
                        album: album.name,
                        artist: artist.name,
                        artwork: !artwork ? [] : [
                            { src: artwork, type: 'image/jpeg' }
                        ]
                    });
                    // navigator.mediaSession.setPositionState({
                    //     duration,
                    //     position: audio.currentTime > duration ? 0 : audio.currentTime,
                    //     playbackRate: 1,
                    // })
                    navigator.mediaSession.setActionHandler('previoustrack', () => {
                        prev();
                    });
                    navigator.mediaSession.setActionHandler('nexttrack', () => {
                        next();
                    });
                    navigator.mediaSession.setActionHandler('stop', () => {
                        // console.log(new Date().toISOString(), 'mediaSession on stop')
                        setPlayingAndOffset(false, 0);
                        audio.pause();
                    });
                    navigator.mediaSession.setActionHandler('pause', () => {
                        // console.log(new Date().toISOString(), 'mediaSession on pause')
                        setPlayingAndOffset(false, audio.currentTime);
                        audio.pause();
                    });
                    navigator.mediaSession.setActionHandler('play', () => {
                        // console.log(new Date().toISOString(), 'mediaSession on play')
                        setPlaying(true);
                    });
                    navigator.mediaSession.setActionHandler('seekto', (e) => {
                        if (e.fastSeek && 'fastSeek' in audio)
                            audio.fastSeek(e.seekTime);
                        else
                            audio.currentTime = e.seekTime;
                        // console.log(new Date().toISOString(), 'setPositionState', duration, audio.currentTime)
                        navigator.mediaSession.setPositionState({
                            duration,
                            position: audio.currentTime,
                            playbackRate: 1,
                        });
                    });
                }
            }
            catch (e) {
                console.error("loadeddata", e);
                setPlaying(false);
                return;
            }
        });
    }, []);
    react_7(() => {
        (async () => {
            if (!localCache)
                return;
            if (!playing)
                return;
            if (!song)
                return;
            if (!audio)
                return;
            const audioUrl = NetworkService.baseurl + '/artists/' + artist.id + '/' + album.id + '/' + song.id + '/' + format + '/' + bitrate;
            if (audio && audio.dataset.audioUrl === audioUrl) {
                return;
            }
            URL.revokeObjectURL(audio.dataset.objectUrl);
            const cachedSong = await localCache.songByUrl(audioUrl);
            // console.log(new Date().toISOString(), 'songByUrl', cachedSong, audioUrl)
            const loadAudio = async (song, data, attachAudio) => {
                // console.log(new Date().toISOString(), 'decoding', song)
                if (!audio.paused) {
                    audio.pause();
                    // console.log(new Date().toISOString(), 'paused')
                }
                if (attachAudio) {
                    if (isSafari) ;
                    else {
                        attachAudio(audio);
                    }
                }
                else if (data) {
                    if (data.byteLength === 0) {
                        next();
                        return;
                    }
                    const blob = new Blob([new Uint8Array(data, 0, data.byteLength)]);
                    if (isSafari) {
                        let sourceElement = document.createElement('source');
                        audio.childNodes[0] && audio.removeChild(audio.childNodes[0]);
                        audio.appendChild(sourceElement);
                        sourceElement.src = audio.dataset.objectUrl = URL.createObjectURL(blob);
                        sourceElement.type = song.type;
                    }
                    else {
                        audio.src = audio.dataset.objectUrl = URL.createObjectURL(blob);
                    }
                }
                else {
                    audio.src = audioUrl + '/stream';
                }
                // audio.srcObject = blob
                // audio['type'] = song.type
                audio.dataset.audioUrl = audioUrl;
                // audio.load()
            };
            if (!cachedSong) {
                // console.log(new Date().toISOString(), 'downloading', audioUrl)
                download(audioUrl, artist.name, album.name, song.title, format, bitrate, loadAudio);
            }
            else {
                const sd = await localCache.songData(cachedSong.dataId);
                if (!sd) {
                    download(audioUrl, artist.name, album.name, song.title, format, bitrate, loadAudio);
                }
                else
                    loadAudio(cachedSong, sd.data);
            }
        })().catch(e => {
            console.error("error while playing", e);
        });
    }, [localCache, playing, song, audio]);
    react_7(() => {
        if (!playing)
            return;
        if (!audio)
            return;
        // console.log(new Date().toISOString(), 'set offset', offset, playing)
        audio.currentTime = offset;
        // console.log('audio.play()', audio.src)
        // if (!audio.src) {
        //     return
        // }
        audio.play().catch(e => {
            console.error("play failed", e);
            if (store$2.get().playing) {
                console.log('try start playing again', audio.src);
                audio.play().catch(e => {
                    console.error("play failed again", e);
                });
            }
        });
    }, [offset, playing]);
    react_7(() => {
        if (!audio)
            return;
        if (!Number.isFinite(volume))
            return;
        audio.volume = Math.max(0, Math.min(volume, 1));
    }, [audio, volume]);
    react_7(() => {
        if (playing)
            return;
        if (!duration)
            return;
        const progress = offset / duration * 100;
        setProgress(Math.min(progress, 100));
    }, [playing, offset, duration]);
    let isMobile = window.innerWidth <= 800;
    return jsxRuntime_1("div", { className: u `position:fixed;bottom:0px;left:0px;right:0px;user-select:none;height:151px;overflow:hidden;`, children: jsxRuntime_1("div", { className: u `padding:20px;`, children: jsxRuntime_2("div", { className: u `max-width:600px;margin-left:auto;margin-right:auto;`, children: [song && jsxRuntime_2(FlexRow, { className: u `justify-content:center;flex-wrap:wrap;height:33px;cursor:pointer;`, onClick: e => {
                            pushLocation('/' + artist?.id + '/' + album?.path);
                        }, children: [jsxRuntime_1("b", { className: u `white-space:nowrap;overflow:hidden;text-overflow:ellipsis;`, children: song.title }), jsxRuntime_2("span", { className: u `font-size:12px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;`, children: ["\u00A0\u00A0by\u00A0\u00A0", artist.name] })] }), jsxRuntime_2(FlexRow, { children: [jsxRuntime_1("span", { className: u `margin-right:10px;`, children: audio && formatDuration(duration * 1000 / 100 * progress) }), jsxRuntime_1(ProgressBar, { draggable: false, progress: progress, onClick: progress => {
                                    if (!audio)
                                        return;
                                    let offset = duration / 100 * progress;
                                    setOffset(offset);
                                    setPlaying(true);
                                } }), jsxRuntime_1("span", { className: u `margin-left:10px;`, children: audio && formatDuration(duration * 1000) })] }), jsxRuntime_2(FlexRow, { className: u `margin-top:10px;`, children: [!isMobile && jsxRuntime_1(VolumeControl, {}), jsxRuntime_2(FlexRow, { className: u `justify-content:center;flex-grow:1;`, children: [jsxRuntime_1(Button, { className: classNames('gray', u `.MaterialIcon{font-size:20px;color:gray;}`), flat: true, round: true, onClick: e => {
                                            prev();
                                            setOffset(0);
                                            setPlaying(true);
                                        }, children: jsxRuntime_1(MaterialIcon, { icon: 'skip_previous' }) }), jsxRuntime_1(Button, { className: classNames('red', u `padding:10px !important;height:unset;margin-left:20px;margin-right:20px;.MaterialIcon{font-size:30px;color:white;}`), flat: true, round: true, onClick: e => {
                                            audio?.pause();
                                            if (queue.length) {
                                                if (audio && playing)
                                                    setPlayingAndOffset(!playing, audio.currentTime);
                                                else
                                                    setPlaying(!playing);
                                            }
                                        }, children: jsxRuntime_1(MaterialIcon, { icon: !playing ? 'play_arrow' : 'pause' }) }), jsxRuntime_1(Button, { className: classNames('gray', u `.MaterialIcon{font-size:20px;color:gray;}`), flat: true, round: true, onClick: e => {
                                            next();
                                            setOffset(0);
                                            setPlaying(true);
                                        }, children: jsxRuntime_1(MaterialIcon, { icon: 'skip_next' }) })] })] })] }) }) });
};
const VolumeControl = ({ className }) => {
    const { volume, playing } = useStore(store$2);
    const [isDragging, setIsDragging] = react_8(false);
    react_7(() => {
        if (isDragging) {
            let removeListeners;
            let upListener = ev => {
                setIsDragging(false);
                removeListeners();
            };
            removeListeners = () => {
                document.removeEventListener('mouseup', upListener);
            };
            document.addEventListener('mouseup', upListener);
            return removeListeners;
        }
    }, [isDragging]);
    return jsxRuntime_2(FlexRow, { className: classNames(u `position:absolute;.MaterialIcon{color:${playing ? 'black' :'grey'};margin-right:10px;}.ProgressBar{width:0px;transition:width 0.2s ease-out;.progress:after{opacity:0;}}&:hover, &.hover{.ProgressBar{width:100px;.progress:after{opacity:1;}}}`, className, isDragging && 'hover'), onMouseDown: e => setIsDragging(true), children: [jsxRuntime_1(MaterialIcon, { icon: 'volume_up' }), jsxRuntime_1(ProgressBar, { draggable: true, progress: volume * 100, onClick: volume => setVolume(volume / 100) })] });
};

export { Player as default };
