import { jsxs as jsxRuntime_2, jsx as jsxRuntime_1 } from 'preact/jsx-runtime';
import { useState as react_8 } from 'preact/compat';
import TextField from '../../node_modules/react-ui-basics/TextField.js';
import Button from '../../node_modules/react-ui-basics/Button.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';
import { FlexRow, FlexColumn } from './SharedComponents.js';
import NetworkService from '../services/NetworkService.js';
import { setTokenValidUntil } from '../stores/AuthenticationStore.js';

const LoginForm = ({}) => {
    const [username, setUsername] = react_8('');
    const [password, setPassword] = react_8('');
    return jsxRuntime_2("form", { className: u `margin-left:auto;margin-right:auto;width:300px;margin-top:5vh;`, onSubmit: async (e) => {
            e.preventDefault();
            try {
                const response = await NetworkService.login({ username, password });
                setTokenValidUntil(response.validUntil);
            }
            catch (e) {
                console.log('catch', e, e.status);
            }
        }, children: [jsxRuntime_1(FlexRow, { children: jsxRuntime_1("img", { className: u `max-width:300px;`, src: '/static/icon-512x512-t.png' }) }), jsxRuntime_2(FlexColumn, { className: u `background:white;padding:30px;border-radius:4px;align-items:center;`, children: [jsxRuntime_1(TextField, { label: 'username', name: 'username', onChange: e => setUsername(e.target.value) }), jsxRuntime_1("br", {}), jsxRuntime_1("br", {}), jsxRuntime_1(TextField, { label: 'password', name: 'password', type: 'password', onChange: e => setPassword(e.target.value) }), jsxRuntime_1("br", {}), jsxRuntime_1("br", {}), jsxRuntime_1(Button, { className: 'blue', type: 'submit', children: "Login" })] })] });
};

export { LoginForm as default };
